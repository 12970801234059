import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  dark: false,
  theme: {
    themes: {
      light: {
        header: "#03132C", //old
        // header: "#FFFF00",
        // header: "#2E6986",
        primary: "#03132C", //old
        // primary: "#1a1a1a",
        // secondary: "#c4e7ff", light blue
        // secondary: "#9CAEA9", //old
        secondary: "#ECEAE9",
        // secondary: "#101820",
        // yellow: "#FED501",
        yellow: "#EBC363",
        lightgrey: "#F4EFE9",
        lightpink: "#F5EBE0",
        grey: "#343434",
        error: "#B22222",
        white: "#ffffff",
        black: "#110916",
        honeydew: "#f0fff0",
        orange: "#DD6031",
        brown1: "#a68053",
        brown: "#9B6D1E",
        lightbrown: "#EABE7C",
        green: "285238",
      },
    },
  },
});

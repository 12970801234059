<template>
    <v-form ref="contactForm" class="contactForm" @submit.prevent="saveLead">
        <v-row justify="center" no-gutters>
            <v-col cols="10" lg="8" md="8">
                <v-row>
                    <v-col cols="12">
                        <v-text-field color="primary" v-model="formData.name" placeholder="Name" outlined
                            hide-details="auto" :rules="[rules.required]" dense autoComplete="nope">
                        </v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field color="primary" v-model="formData.phone" placeholder="Phone" outlined
                            hide-spin-buttons hide-details="auto" type="number" :rules="[rules.required, rules.phone]"
                            dense autoComplete="nope">
                        </v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field color="primary" v-model="formData.email" placeholder="Email" outlined
                            hide-details="auto" :rules="[rules.required, rules.email]" dense autoComplete="nope">
                        </v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-textarea color="primary" v-model="formData.message" placeholder="Type your message here..."
                            rows="4" auto-grow outlined hide-details="auto" :rules="[rules.required]" dense
                            autoComplete="nope">
                        </v-textarea>
                    </v-col>

                    <v-col cols="12">
                        <v-btn depressed color="primary" class="secondary--text" type="submit">Submit</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
// @ is an alias to /src
import { saveLead } from "@/services/contactus/contactusService"
export default {
    name: 'ContactForm',
    components: {

    },
    data() {
        return {
            formData: {
                name: "",
                phone: "",
                email: "",
                message: "",
            },
            rules: {
                required: value => !!value || 'Required.',
                phone: value => value && value.length === 10 || 'Max 10 characters',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            }
        }
    },
    methods: {
        saveLead() {
            if (this.$refs.contactForm.validate()) {
                saveLead(this.formData)
                    .then((response) => {
                        this.$toast.success(response.data.message);
                        this.$refs.contactForm.reset();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    }
}
</script>
  
<template>
  <v-app>
    <v-overlay :value="getSpinner" opacity="0.6" color="primary">
      <v-progress-circular indeterminate color="secondary" size="40">
      </v-progress-circular>
    </v-overlay>

    <v-app-bar
      app
      elevation="0"
      fixed
      color="secondary"
      flat
      clipped-left
    >
      <v-app-bar-nav-icon
        color="primary"
        class="d-lg-none d-md-none"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
      <app-header></app-header>
    </v-app-bar>

    <v-navigation-drawer
      app
      v-model="drawer"
      :value="drawer"
      class="navigation-drawer d-lg-none d-md-none"
      clipped
      touchless
      fixed
      disable-resize-watcher
      color="secondary darken-1"
    >
      <app-menu @closeDrawer="drawer = false" />
    </v-navigation-drawer>

    <v-main app ref="main" id="main" class="secondary">
      <v-container fluid :class="isHomePage ? 'pa-0' : 'pa-3'">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
        <div
          class="whats-app-chat-bot cursor-pointer"
          @click="chatNow"
        >
          <v-btn dense color="white--text" dark large>
            <v-icon class="mr-1" color="white"> mdi-whatsapp </v-icon>
            Whats App
          </v-btn>
        </div>
      </v-container>
    </v-main>
    <v-footer color="secondary darken-1">
      <app-footer></app-footer>
    </v-footer>
  </v-app>
</template>

<script>
import AppHeader from "@/components/Header.vue";
import AppFooter from "@/components/Footer.vue";
import AppMenu from "@/components/Menus.vue";
import { mapGetters } from "vuex";
export default {
  name: "app",
  watch: {
    "$route.name"(name) {
      if (name != "HomeView") {
        this.$vuetify.goTo(0, {
          duration: 650,
          offset: 0,
        });
      }
    },
  },
  data() {
    return {
      drawer: false,
      paymentDrawer: true,
    };
  },
  components: {
    AppHeader,
    AppFooter,
    AppMenu,
  },
  computed: {
    ...mapGetters(["getSpinner", "isMobile"]),
    isHomePage() {
      return ["HomeView"].includes(this.$route.name);
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      if (window.innerWidth < 960) {
        this.$store.commit("setMobile", true);
      } else {
        this.$store.commit("setMobile", false);
      }
    },
    chatNow() {
      window.open(
        "https://api.whatsapp.com/send/?phone=9686411733&text&type=phone_number&app_absent=0"
      );
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style>
@import "./main.css";
</style>

<style>
#app {
  font-family: "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  scroll-behavior: smooth;
}

.whats-app-chat-bot {
  position: fixed;
  right: 10px;
  bottom: 20px;
  transform: translate(-10px, -20px);
  z-index: 9;
  margin: 0;
}
.whats-app-chat-bot .v-btn {
  border-radius: 25px;
  background-color: #4dc247 !important;
}

.whats-app-chat-bot .animate-icon {
  border-radius: 50%;
  /* box-shadow: 0 0 0 0 rgba(0, 128, 0, 1); */
  /* box-shadow: 0 0 0 0 rgb(255, 255, 255); */
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.router-link-exact-active {
  border-bottom: 2px solid black;
}
</style>

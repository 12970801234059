<template>
  <v-container fluid>
    <v-row no-gutters class="header" justify="center">
      <v-col
        cols="8"
        lg="12"
        md="10"
        align-self="center"
        class="d-none d-lg-block d-md-block"
      >
        <app-menu @closeDrawer="drawer = false"></app-menu>
      </v-col>
      <v-col cols="12" class="d-block d-lg-none d-md-none text-right">
        <div class="menu-text secondary">
          <v-btn
            dark
            outlined
            color="primary"
            exact
            :to="{ name: 'BookNow' }"
            class="primary--text"
          >
            Book Now
          </v-btn>
          <!-- <v-btn
            class="text-left"
            dark
            outlined
            color="secondary lighten-2"
            exact
          >
            <a
              class="secondary--text"
              style="text-decoration: none"
              href="tel:+919686411733"
            >
              <b>9686411733</b>
            </a>
          </v-btn> -->
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppMenu from "@/components/Menus.vue";

export default {
  name: "AppHeader",
  components: {
    AppMenu,
  },
  mounted() {},
  methods: {},
};
</script>
<style>
.header .logo .logo-text {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

.header .logo-text a {
  font-weight: 600;
  text-decoration: none;
}
</style>
